import React from 'react';
import {
    BannerSection, BannerContents, BannerContentsLeft
    , ButtonLayout, CallNowBtn
} from './banner.style';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonDropDown from '../ButtonDropDown';
import ScrollSpy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './custom.css';

const BannerSectionCustom = ({ data }) => {
    const BGImage = data.PathToImage;
    return (
        <BannerSection id="bannerCustomContainer" style={{ backgroundImage: `url(${BGImage})` }}>
            <Container>
                <BannerContents>
                    <BannerContentsLeft>
                        <h1 className="contrastText">
                            {data.BannerHeading}
                        </h1>
                        <p className="noContrastText">
                            {data.BannerDescriptionExtra}
                        </p>
                        <p className="contrastText">
                            {data.BannerDescription}
                        </p>
                        <ButtonLayout>


                            {data.BtnText ?
                                (data.BtnText.MultiSelection ?
                                    (
                                        <ButtonDropDown data={data.BtnText} />
                                    )
                                    : data.BtnText.LocalLink ? (
                                        <ScrollSpy items={data.Banner} currentClassName="is-current">
                                            <AnchorLink href={data.BtnText.Items[0].Href}>
                                                <CallNowBtn href={data.BtnText.Items[0].Href}>{data.BtnText.Title}</CallNowBtn>
                                            </AnchorLink>
                                        </ScrollSpy>
                                    )
                                        : (
                                            <CallNowBtn href={data.BtnText.Items[0].Href} target="_blank">{data.BtnText.Title}</CallNowBtn>
                                        )
                                ) : null
                            }


                            {data.BtnText2 ?
                                (data.BtnText2.MultiSelection ?
                                    (
                                        <ButtonDropDown data={data.BtnText2} />
                                    ) : (
                                        <CallNowBtn href={data.BtnText2.Items[0].Href} target="_blank">{data.BtnText2.Title}</CallNowBtn>
                                    )
                                ) : null
                            }




                        </ButtonLayout>
                    </BannerContentsLeft>
                </BannerContents>
            </Container>
        </BannerSection >
    );
}

export default BannerSectionCustom;
