import styled from 'styled-components';
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {device} from '../Common/device'
import {Send} from '@styled-icons/material/Send'

export const ContactSection = styled.section`
    padding:80px 0px;
    @media ${device.tablet} {
        padding-right: 10px;
        padding-left: 10px;
    }
`;

export const ContactInner = styled.div`
`;

export const ContactRow = styled(Row)`
    align-items:center;
`;

export const ContactLeft = styled.div`
    h1{
        margin-bottom:25px;
        text-transform:uppercase;
    }
    p{

    }
`;

export const Form = styled.form`
    margin-bottom:0px;
`;

export const FormGroup = styled.div`
    margin-bottom:20px;
`;

export const InputField = styled.input`
width:100%;
border:none;
border-radius:6px;
background:#f7f7f7;
// box-shadow: 0px 0px 12px #e4e4e4;
padding:15px;
outline:0;
border:1px solid #eee;
::placeholder
{
    font-size:16px;
    color: #7a7f83;
}
`;

export const ContactSpanErr = styled.span`
    color:red;
    font-size:14px;
`;

export const TextArea = styled.textarea`
width:100%;
border:none;
border-radius:6px;
background:#f7f7f7;
padding:15px;
height:120px;
outline:0;
border:1px solid #eee;
::placeholder
{
    font-size:16px;
    color: #7a7f83;
}
`;
export const SubmitBtn = styled.button`
display:block;
width:100%;
font-size:18px;
color:#fff;
outline:0;
cursor:pointer;
border:none;
padding:15px;
border-radius:6px;
transition:all .5s;
background: #ef5350 !important;
:hover
{
    background:#6028fa !important;
}
`;


export const ContactCard = styled.div`
background:#fff;
padding:50px;
box-shadow: 0px 0px 12px #e4e4e4;
border-radius:10px;
@media ${device.laptop} {
    padding:40px;
}
@media ${device.tablet} {
    padding:25px;
}
`;

export const SendIcon = styled(Send)`
    width: 25px;
    left: 10px;
    position: relative;
    transition:all .5s;

    ${SubmitBtn}:hover &{
        left:20px;
        transition:all .5s;
    }
`;

export const ContactSpanSuccess = styled.span`
    color:green;
    font-size:14px;
`;



export const ContactRight = styled.div`
padding-left:100px;

@media ${device.laptop} {
    padding-left:40px;
}
@media ${device.tablet} {
    padding-bottom:50px;
    padding-right:0px;
    padding-left:0px;
}
`;


export const ContactRightRow = styled.div`
    margin:25px 0px;
    display:flex;
    align-items:center;
`;

export const ContactRightImgHolder = styled.div`
    width:40px;
    flex-shrink:0;
`;

export const ContactRightImg = styled.img`
    margin-bottom: 0px;
    vertical-align: middle;
`;

export const ContactRightTextHolder = styled.div`
    margin-left:15px;
`;

export const ContactRightText = styled.p`
    margin-bottom:0px;
`;
