import React from 'react';
import CookieConsent from "react-cookie-consent";

const CookieContainer = ({sharedData}) => {

        return (
            <CookieConsent
            enableDeclineButton
            location="bottom"
            buttonText={sharedData.OkButtonMessage}
            cookieName={sharedData.CookieName}
            style={{ background: "#181818" , fontFamily: "'Barlow',sans-serif", justifyContent: "center"}}
            buttonStyle={{ background: "#5f27fa", color: "#ffffff", fontSize: "13px" , fontFamily: "'Barlow',sans-serif", borderRadius: "4px"}}
            declineButtonStyle={{ background: "#ef5350", color: "#ffffff", fontSize: "13px" , fontFamily: "'Barlow',sans-serif", borderRadius: "4px"}}
            contentStyle={{ maxWidth: "770px"}}	
            expires={sharedData.CookieExpireDays}
            setDeclineCookie={sharedData.SaveCookieOnDecline}
            >
                <div dangerouslySetInnerHTML={{ __html: sharedData.CookieMessage }} />          
            </CookieConsent>
    );
}

export default CookieContainer;