import React from 'react';
import {PricingSection} from './partners.style';
import 'bootstrap/dist/css/bootstrap.min.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import clsx from 'clsx'

const Partners = ({data}) =>{

    const settings = {
      dots: false,
      prevArrow: false,
      nextArrow: false,
      pauseOnHover: false,

      infinite: true,
      speed: 6000,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: 'linear',
      slidesToShow: 8,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              initialSlide: 1
          }
        },
        {
          breakpoint: 761,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1
          }
        }
      ]
    };

    return (
      <PricingSection id="partnersContainer" style={{background: "#FFF"}}>
          <Slider {...settings}>
            {
              data.map((item,idx) => {
                  if (item.fields && item.fields.Logo) {
                      return <img
                          id={idx}
                          className={clsx('logo', {
                              'monochrome': item.fields.UseMonochromaticFilter
                          })}
                          src={`..${item.fields.Logo.serverRelativeUrl}`}
                          alt={item.fields.PartnerName}
                      />
                  }
                  return null
              })
            }
          </Slider>
      </PricingSection>
    );
}

export default Partners;
