import React,{Component} from "react";
import {NavbarCustom,NavbarToggleCustom,BrandLogo} from "./headermenubs.style"
import {
    Container,Nav,Navbar
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollSpy from 'react-scrollspy'
import AnchorLink from 'react-anchor-link-smooth-scroll';

class HeadermenuCustom extends Component{
    constructor(props){
        super(props);
        this.state = {
            stickyClass:'top'
        };
    }

    componentDidMount(){
        window.addEventListener('scroll', () => {
            let stickyClass = 'topSticky';
            let scrollPos = window.scrollY;

            if(scrollPos < 100){
                stickyClass = 'top';
            }

            if(this.state.stickyClass !== stickyClass)
            {
                this.setState({ stickyClass });
            }

        });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', null);
    }

    render(){

        return(
            <NavbarCustom
                expanded={this.props.expanded}
                expand="lg" fixed="top"
                isSticky={this.state.stickyClass==="topSticky"?true:false}>
                <Container>
                    <Navbar.Brand href="/">
                        <BrandLogo src={this.state.stickyClass==="topSticky"?this.props.data.BrandLogoSticky:this.props.data.BrandLogo}
                        isSticky={this.state.stickyClass==="topSticky"?true:false}
                        alt="Logo"
                        />
                    </Navbar.Brand>

                    <NavbarToggleCustom
                    isSticky={this.state.stickyClass==="topSticky"?true:false}
                    aria-controls="basic-navbar-nav" 
                    onClick={() => this.props.expandToggle(!this.props.expanded )  } 
                    />

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <ScrollSpy offset={-59} items={this.props.data.Items} currentClassName="is-current">
                            {
                                
                                this.props.data.MenuItems.map((menuItem, idx)=>{                                 
                                        return menuItem.LocalLink ? (
                                            <li>
                                            <AnchorLink offset={55} href={menuItem.Href}
                                            isSticky={this.state.stickyClass==="topSticky"?true:false} onClick={() =>  this.props.expandToggle(false)   }>
                                                {menuItem.Menu}
                                            </AnchorLink>
                                            </li>
                                        ) : (
                                            <li>
                                                <a  onClick={() =>  this.props.expandToggle(false) } href={menuItem.Href} rel="noreferrer" target="_blank" >{menuItem.Menu}</a>
                                            </li>
                                            
                                        )
                                    
                                })
                            }
                            </ScrollSpy>
                        </Nav>
                    </Navbar.Collapse>

                </Container>
            </NavbarCustom>

        );
    }
}

export default HeadermenuCustom;