import styled from 'styled-components';

export const PricingSection = styled.div`
  .slick-slider {
    line-height: 0;
    width: auto !important;
  }

  .slick-slide {
    padding: 0 10px;

    img {
      margin: 0;
      max-width: 150px;
      max-height: 75px;
      height: auto;
      width: auto !important;
      margin-left: 10px;
      margin-right: 10px;
    }
    
    img.monochrome {
      -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
      filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
    }
  }
  
  .slick-track {
    display: flex;
  }
  
  .slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
    width: auto !important;
  }

  padding:40px 0px 40px 0px;
`;
