import React,{Component} from 'react';
import {Container, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ContactSection,ContactInner,ContactLeft,InputField,ContactSpanErr,ContactSpanSuccess,
    TextArea,SubmitBtn,ContactRight,FormGroup,Form,ContactRow,ContactCard} from './contact.style';
import Fade from "react-reveal/Fade";
import ReCaptcha from "@pittica/gatsby-plugin-recaptcha"

const WRNG_MSG_TIMEOUT = 3000;

class ContactPoc extends Component{

    constructor(props){
        super(props);
        this.state = {
           contact_name:"",
           contact_email:"",
           contact_subject:"",
           contact_message:"",
           errors:[],
           submited: false
        }
        this.siteKey = process.env.GATSBY_RECAPTCHA_SITEKEY;
        this.color =  this.props.data.BackgroundGrey ? "#f9fafc" : "#FFFFFF"
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    validateForm()
    {
        var error_flag = false;
        let errors = {};
        if(this.state.contact_name === "")
        {
            error_flag = true;
            errors['contact_name'] = "Please enter full name!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.contact_email === "")
        {
            error_flag = true;
            errors['contact_email'] = "Please enter email!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.contact_email !== "")
        {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if(!pattern.test(this.state.contact_email)) {
                error_flag = true;
                errors["contact_email"] = "Please enter valid email ID!";
                setTimeout(function(){
                    this.setState({errors:{}});
               }.bind(this),WRNG_MSG_TIMEOUT);
            }
        }

        if(this.state.contact_message === "")
        {
            error_flag = true;
            errors["contact_message"] = "Please enter message!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        this.setState({
            errors: errors
        });
        return error_flag;
    }

    contactUsSubmit = async (e) =>{
        e.preventDefault();
        document.getElementById("success_contact_message").innerHTML = "";
        document.getElementById("err_contactAll_message").innerHTML = "";
        if(!this.validateForm())
        {
            this.setState({submited:true});
        }
    }

    async postFormToServer (token) {
        this.setState({submited:false});

        const appName = this.props.appName;

        var contactObj = {
            Name : this.state.contact_name,
            EmailAddress : this.state.contact_email,
            Message : this.state.contact_message,
            Token: token,
            App: appName,
            Country: "",
            Company: "",
            PhoneNumber: ""
       };

        let dataStringify =  JSON.stringify(contactObj);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: dataStringify
        };

        this.props.blockUIChange(true);


        const azureFunctionURL = "/api/Lead/ContactRequest";
        fetch(azureFunctionURL, requestOptions)
            .then(response => {
                if(response.ok) {
                    //show message! sucesso
                    document.getElementById("success_contact_message").innerHTML = "Email sent successfully.";
                    this.setState({
                        contact_name:"",
                        contact_email:"",
                        contact_subject:"",
                        contact_message:"",
                    });
                    this.props.blockUIChange(false);

                } else {                
                    document.getElementById("err_contactAll_message").innerHTML = "An error occurred! Please try again later! If the error persist contact us via email at:  <a  href='mailto:support@devscope.net?Subject=PowerBI%20Tiles'>support@devscope.net</a>";
                    this.props.blockUIChange(false);
                }
            })
            .catch(error => {
                document.getElementById("err_contactAll_message").innerHTML = "An error occurred! Please try again later! If the error persist contact us via email at:  <a  href='mailto:support@devscope.net?Subject=PowerBI%20Tiles'>support@devscope.net</a>";
                this.props.blockUIChange(false);
            });

            
    }


    render(){
        return(
            <React.Fragment>

                    <ReCaptcha
                        action="homepage"
                        siteKey={this.siteKey}
                        onVerify={(token) => this.postFormToServer(token)}
                        submitted={this.state.submited}
                        badge="bottomright"
                    />

                    <ContactSection id="contactContainer" style={{background: this.color}}>
                        <Container>
                            <ContactInner>
                                <ContactRow>
                                    <Col md="5">
                                        <ContactLeft>
                                            <Fade left >
                                                <h1>{this.props.sharedData.SectionHeading}</h1>
                                            </Fade>
                                            <Fade left >
                                                <p>{this.props.sharedData.ContactRight.ContactRightPara}</p>
                                            </Fade>
                                        </ContactLeft>
                                    </Col>
                                    <Col md="7">
                                        <ContactRight>
                                            <Fade right >
                                                <ContactCard>
                                                    <Form id="contactForm" method="post" onSubmit={this.contactUsSubmit}>

                                                        <FormGroup>
                                                            <InputField aria-label="Full Name" placeholder="Full Name" name="contact_name" onChange={this.handleChange} value={this.state.contact_name}></InputField>
                                                            <ContactSpanErr id="err_contact_name">{this.state.errors.contact_name}</ContactSpanErr>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <InputField aria-label="Email" placeholder="Email" name="contact_email" onChange={this.handleChange} value={this.state.contact_email}></InputField>
                                                            <ContactSpanErr id="err_contact_email">{this.state.errors.contact_email}</ContactSpanErr>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <TextArea aria-label="Message" placeholder="Message" name="contact_message" onChange={this.handleChange} value={this.state.contact_message}></TextArea>
                                                            <ContactSpanErr id="err_contact_message">{this.state.errors.contact_message}</ContactSpanErr>
                                                        </FormGroup>

                                                        <SubmitBtn type="submit">Submit</SubmitBtn>
                                                        <ContactSpanErr id="err_contactAll_message"></ContactSpanErr>
                                                        <ContactSpanSuccess id="success_contact_message"></ContactSpanSuccess>
                                                    </Form>
                                                </ContactCard>
                                            </Fade>
                                        </ContactRight>
                                    </Col>
                                </ContactRow>
                            </ContactInner>
                        </Container>
                    </ContactSection>
                
            </React.Fragment>

        )
    }
}

export default ContactPoc;