import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import './custom.css';

const ButtonDropDown = ({data}) => {
    var items = data.Items;

    return (
        <DropdownButton id="dropdown-basic-button"  className='outerDiv' title={data.Title} style={{backgroundColor: "#5f27fa"}}>
            {
                items.map((item,key) => {
                    return  <Dropdown.Item href={item.Href} target="_blank">{item.Name}</Dropdown.Item>
                })
            }
        </DropdownButton>
    );
}

export default ButtonDropDown;
